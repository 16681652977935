import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Options, OptionFormData } from "./option.type";
import { prepareHeaders } from "../user/user.api";

export const OptionApi = createApi({
  reducerPath: "optionApi",
  tagTypes: ["option"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    updateOption: builder.mutation<Options, OptionFormData>({
      invalidatesTags: ["option"],
      query: ({ slug, ...data }) => {
        return {
          url: `options/${slug}/`,
          method: "PUT",
          body: data,
        };
      },
    }),
    getOption: builder.query<Options, void>({
      query: () => `options/`,
      providesTags: ["option"],
    }),
  }),
});

export const {useGetOptionQuery,useUpdateOptionMutation} = OptionApi