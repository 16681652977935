/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import {
  OptionCivite,
  OrderFormData,
} from "../../../../utils/api/order/order.type";
import { FormError, Input } from "../../../common/Input";
import { SelectInput } from "../../../common/SelectInput";

export type PreOrderStepType = {
  navigation: any;
  register: any;
  onSubmit: any;
  setValue: any;
  errors: any;
  isLoading: boolean;
  phone: any;
  setPhone: any;
  reset: any;
  dataForm: OrderFormData;
  checked: boolean;
  handleChecked: (value: boolean) => void;
  setCode: any;
};

export const optionsCivilite = [
  {
    label: "Monsieur",
    value: OptionCivite.monsieur,
  },
  {
    label: "Madame",
    value: OptionCivite.madame,
  },
  {
    label: "Mademoiselle",
    value: OptionCivite.mademoiselle,
  },
];

function UserInfosStep({
  register,
  setValue,
  errors,
  isLoading,
  phone,
  setPhone,
  checked,
  handleChecked,
  onSubmit,
  setCode,
}: PreOrderStepType) {
  const ref = useRef<any>();

  useEffect(() => {
    if (errors?.telephone?.message) {
      ref.current.scrollIntoView();
    }
  }, [errors?.telephone]);

  return (
    <form onSubmit={onSubmit}>
      <div className="row row-form my-4">
        <div className="col-md-6 col-form-modal mb-3">
          <Input
            label="Prénom"
            id="prenom"
            type="text"
            required
            placeholder="Prénom"
            {...register("prenom")}
            error={errors?.prenom}
            className="form-control"
            autoFocus
          />
        </div>
        <div className="col-md-6 col-form-modal mb-3">
          <Input
            label="Nom"
            id="nom"
            type="text"
            required
            placeholder="Nom"
            {...register("nom")}
            error={errors?.nom}
            className="form-control"
          />
        </div>
        <div className="col-md-6 col-form-modal mb-3">
          <Input
            label="Adresse mail"
            id="tag"
            type="text"
            required
            placeholder="Adresse mail"
            {...register("email")}
            error={errors?.email}
            className="form-control"
            inputMode="text"
          />
        </div>
        <div className="col-md-6 col-form-modal mb-3">
          <label
            htmlFor="phone"
            className="form-label form-label-modal-custom"
            aria-labelledby="phone"
          >
            Téléphone
            <span ref={ref} className="text-danger" style={{ fontSize: 24 }}>
              *
            </span>
          </label>
          <PhoneInput
            defaultMask={".. ... .. .."}
            containerClass="form-control auth-form-control mb-3 p-0"
            inputClass="form-control form-control-modal-custom"
            country={"sn"}
            placeholder=""
            inputProps={{
              name: "telephone",
              required: true,
              // autoFocus: true,
            }}
            countryCodeEditable={false}
            enableAreaCodes={true}
            prefix="+"
            value={phone}
            onChange={(phone, country: any) => {
              setPhone(phone);
              setCode(country?.countryCode);
              if (country?.format?.match(/\./g)?.length === phone?.length) {
                setValue("telephone", "+" + phone);
              }
            }}
            inputStyle={{
              width: "100%",
              paddingBottom: "22px",
              borderRadius: "10px",
            }}
          />
          {errors?.telephone && (
            <FormError error={errors?.telephone?.message} />
          )}
        </div>
        <div className="col-md-6 col-form-modal mb-3">
          <SelectInput
            label="Civilité"
            options={optionsCivilite}
            {...register("civilite")}
            required
          />
          {errors?.civilite && <FormError error={errors?.civilite?.message} />}
        </div>
        <div className="col-md-6 col-form-modal mb-3">
          <label htmlFor="" className="form-label form-label-modal-custom">
            Avez-vous un enfant de moins de 5 ans
            <span className="text-danger" style={{ fontSize: 24 }}>
              *
            </span>
          </label>
          <div className="d-flex gap-3">
            <label
              htmlFor="yes"
              className={`form-radio-label form-radio-label-oui ${
                checked === true ? "checked" : ""
              }`}
            >
              oui
              <input
                value={true}
                type="radio"
                id="yes"
                {...register("enfant_moins_de_5_ans")}
                hidden
                onChange={() => handleChecked(true)}
              />
            </label>
            <label
              htmlFor="no"
              className={`form-radio-label form-radio-label-non ${
                checked === false ? "checked" : ""
              }`}
            >
              non
              <input
                value={false}
                type="radio"
                id="no"
                {...register("enfant_moins_de_5_ans")}
                hidden
                onChange={() => handleChecked(false)}
              />
            </label>
          </div>
          <FormError error={errors?.enfant_moins_de_5_ans} />
        </div>
        <div className="col-md-6 col-form-modal mb-3">
          <Input
            label="Date de naissance"
            id="date_de_naissance"
            type="date"
            min={0}
            defaultValue={0}
            required
            placeholder="Date de naissance"
            {...register("date_de_naissance")}
            error={errors?.date_de_naissance}
            className="form-control"
          />
        </div>
        <div className="col-md-6 col-form-modal mb-3">
          <Input
            label="Lieu de naissance"
            id="lieu_de_naissance"
            type="text"
            required
            placeholder="Lieu de naissance"
            {...register("lieu_de_naissance")}
            error={errors?.lieu_de_naissance}
            className="form-control"
          />
        </div>
        <div className="col-md-6 col-form-modal mb-3">
          <Input
            label="Code postal"
            id="code_postal"
            type="text"
            minLength={5}
            maxLength={5}
            min={0}
            placeholder="Code postal"
            {...register("code_postal")}
            error={errors?.code_postal}
            inputMode="text"
          />
        </div>
      </div>
      <div className="content-col-form-modal flex-r">
        {!isLoading ? (
          <button
            className="btn btn-payer-pre-commande"
            title="Payer"
            type="submit"
          >
            Payer
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-payer-pre-commande"
            disabled={isLoading}
          >
            <span
              className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
              role="status"
            ></span>
            <span>Chargement...</span>
          </button>
        )}
      </div>
    </form>
  );
}

export default UserInfosStep;
