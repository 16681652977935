import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./LandingPage.css";
import AssuranceImg from "../../assets/landingpage/sante.png";
import TransfertImg from "../../assets/landingpage/money.png";
import ForumImg from "../../assets/landingpage/chat2.png";
import Tel from "../../assets/landingpage/tel4.png";
import "react-rater/lib/react-rater.css";
import FooterLandingpage from "./FooterLandingpage/FooterLandingpage";
import DownloadAppSection from "./DownloadAppSection";
import ButtonScrollTop from "./ButtonScrollTop";
import { useGetOffresVisitorQuery } from "../../utils/api/offres/offres.api";
import { IOffres } from "../../utils/api/offres/offres.type";
import Swal from "sweetalert2";
import CapturesCaroussel from "./CapturesCaroussel/CapturesCaroussel";
import { AlertInfo } from "../common/Alert";
import Bounce from "react-reveal/Bounce";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";
import Navbar from "./Navbar";
import Banner from "./Banner";
import Carousel from "react-multi-carousel";
import { createMarkup, formatDeviseFront, formatMontantFCFA, formatOffreFrequenceFront, formatTauxPourcentage } from "../../utils/utils";
import InfosUserForOrderModal from "./Modals/InfosUserForOrderModal";
import { useGetFaqForVisitorsQuery } from "../../utils/api/config/config.api";

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const LandingPage = () => {
  const { data } = useGetOffresVisitorQuery();
  const time = new Date();
  time.setSeconds(5529600);
  // =========== Ordonner les offres pa Id =========
  const listeIds = data?.map((objet) => objet.id);
  listeIds?.sort(function (a, b) {
    return a - b;
  });
  const offres = listeIds?.map((id) => {
    return data?.find((objet) => objet?.id === id);
  });

  const [offre, setOffre] = React.useState<IOffres>();
  const [Index, setIndex] = React.useState<number>();

  const { data: faqs } = useGetFaqForVisitorsQuery();

  const [modalInfosUserIsOpen, setInfosUserIsOpen] = React.useState(false);
  function openModalInfosUser() {
    setInfosUserIsOpen(true);
  }

  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (search?.includes("status=success")) {
      Swal.fire({
        width: "22rem",
        icon: "success",
        iconColor: "#0FBF56",
        html: "<h2 class='swal-message-style'>Votre pré-commande a été bien prise en compte.<br/> Vous serez informés dès que la commande sera disponible</h2>",
        confirmButtonText: "OK",
        confirmButtonColor: "#0FBF56",
      }).then(() => navigate("/"));
    }
    if (search?.includes("status=error")) {
      Swal.fire({
        icon: "error",
        title: `La pré commande à échouée!`,
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {
        navigate("/");
      });
    }
  }, [search]);

  return (
    <div className="landing-page-container">
      <div className="section-banner-landing-page mb-md-5">
        <div className="header-landingpage-container">
          <Navbar />
        </div>
        <div className="crypta-container">
          <div className="container-banner-landingpage">
            <Banner />
          </div>
        </div>
      </div>
      <section className="section-about-crypta my-5">
        <div className="content-section-about-crypta">
          <div className="landingpage-crypta-container">
            <div className="content-title-crypta-landingpage pb-4">
              <Fade left>
                <h2 className="title-crypta-landingpage">
                  Ce que nous vous <br /> proposons
                </h2>
              </Fade>
            </div>
            <div className="row row-about-section mb-md-5">
              <Bounce left>
                <div className="col-md-4 col-about-section-lp mb-3 d-flex">
                  <div className="card-section-about-item color1-card-item w-100">
                    <div className="content-text-card-item text-center">
                      <p className="title-card-item-section-about">
                        Mutuelle de santé{" "}
                      </p>
                      <div className="content-img-card-item">
                        <img
                          src={AssuranceImg}
                          alt="Assurance"
                          className="img-card-item-about-section"
                        />
                      </div>
                      <p className="text-describe-card-item-section-about">
                        Kivu est une solution simple pour permettre aux
                        populations le remboursement d’une partie ou de la
                        totalité de leurs frais médicaux.
                      </p>
                    </div>
                  </div>
                </div>
              </Bounce>
              <Bounce bottom>
                <div className="col-md-4 col-about-section-lp mb-3 d-flex">
                  <div className="card-section-about-item color3-card-item w-100">
                    <div className="content-text-card-item text-center">
                      <p className="title-card-item-section-about">
                        Kivu wallet
                      </p>
                      <div className="content-img-card-item">
                        <img
                          src={TransfertImg}
                          alt="Transfert"
                          className="img-card-item-about-section"
                        />
                      </div>
                      <p className="text-describe-card-item-section-about">
                        Vous pourrez recharger votre porte-monnaie digital («
                        wallet ») pour souscrire aux offres ou vous faire
                        rembourser.
                      </p>
                    </div>
                  </div>
                </div>
              </Bounce>
              <Bounce right>
                <div className="col-md-4 col-about-section-lp mb-3 d-flex">
                  <div className="card-section-about-item color4-card-item w-100">
                    <div className="content-text-card-item text-center">
                      <p className="title-card-item-section-about">FORUM</p>
                      <div className="content-img-card-item">
                        <img
                          src={ForumImg}
                          alt="Assurance"
                          className="img-card-item-about-section"
                        />
                      </div>
                      <p className="text-describe-card-item-section-about">
                        Votre espace d’échange et de discussions entre
                        utilisateurs de Kivu. Ici, vous pourrez débattre sur
                        tous sujets qui vous intéressent et pertinents à
                        l’application.
                      </p>
                    </div>
                  </div>
                </div>
              </Bounce>
            </div>
            <div className="content-title-crypta-landingpage text-center">
              <Slide left>
                <h2 className="title-crypta-landingpage">
                  KIVU - L’application
                </h2>
              </Slide>
            </div>
            <Slide right>
              <div className="content-img-capture mt-5 ">
                <CapturesCaroussel />
              </div>
            </Slide>
          </div>
        </div>
      </section>
      <section className="section-about-crypta section-abonnement mb-md-5">
        <div className="landingpage-crypta-container">
          <div className="abonnement-section">
            <div className="content-title-crypta-landingpage text-center">
              <Slide left>
                <h2 className="title-crypta-landingpage">
                  Abonnement en précommande*
                </h2>
              </Slide>
            </div>
            <Slide right>
              <p className="text-describe-about-section text-center">
                *Rattachement d’un enfant de moins de 5 ans offert par
                précommande
              </p>
            </Slide>
            <Slide bottom>
              <div className="abonnement-container mt-5">
                {offres && offres?.length > 0 ? (
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={false}
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={800}
                    keyBoardControl={true}
                    customTransition="transform 1000ms ease-in-out"
                    transitionDuration={2000}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px py-3"
                    focusOnSelect={true}
                    arrows={true}
                  >
                    {offres?.map((offre: IOffres | undefined, index) => ( 
                      <div
                        className={`abonnement-item abonnement-bc${
                          index + 1
                        } horizontal-scroll__item ms-3`}
                        key={offre?.id}
                      >
                        <h3
                          className={`title-abonnement abonnement-color${
                            index + 1
                          }`}
                        >
                          {/* {offre?.option.replace(
															"_",
															" "
														)} */}
                          {offre?.title}
                        </h3>
                        <div
                          className={`content-span-abonnement bg-span-abonnement${
                            index + 1
                          }`}
                        >
                          <span className="span-abonnement">
                            {formatMontantFCFA(offre?.price, offre?.devise)} {formatDeviseFront(offre?.devise)}/
                            {formatOffreFrequenceFront(offre?.frequence)}
                          </span>
                        </div>
                        <div className="container-abonnement-check-list mb-4 mt-5">
                          {/* <div
                            dangerouslySetInnerHTML={createMarkup(
                              offre?.details
                            )}
                            className="advantages-offer"
                          /> */}
                          <div className="advantages-offer">
                            <ul className="kivu-custom-ul">
                              {offre?.prix_rattachement_adulte && (
                                <li >
                                  {formatMontantFCFA(offre?.prix_rattachement_adulte, offre?.devise)}{" "}
                                  {formatDeviseFront(offre?.devise)}/
                                  {formatOffreFrequenceFront(offre?.frequence)}{" "}
                                  ratachement adulte(1 seule possibilité)
                                </li>
                              )}
                              {offre?.prix_rattachement_enfant && (
                                <li >
                                  {formatMontantFCFA(offre?.prix_rattachement_enfant, offre?.devise)}{" "}
                                  {formatDeviseFront(offre?.devise)}/
                                  {formatOffreFrequenceFront(offre?.frequence)}{" "} par
                                  ratachement enfant -24 ans
                                </li>
                              )}
                              {offre?.taux_couverture_soins_ordonnance && (
                                <li
                                  className={offre?.taux_couverture_soins_ordonnance === "0.00" ? "non-dispo" : ""}
                                >
                                  {formatTauxPourcentage(offre?.taux_couverture_soins_ordonnance)}{" "}Taux soins ordonnance
                                </li>
                              )}
                              {offre?.taux_hospitalisation && (
                                <li
                                  className={offre?.taux_hospitalisation === "0.00" ? "non-dispo" : ""}
                                >
                                  {formatTauxPourcentage(offre?.taux_hospitalisation)}{" "}hospitalisations
                                </li>
                              )}
                              {offre?.taux_couverture_soins_esthetique && (
                                <li className={offre?.taux_couverture_soins_esthetique === "0.00" ? "non-dispo" : ""}>
                                  {formatTauxPourcentage(offre?.taux_couverture_soins_esthetique)}{" "}Appareils (dentition, vue, motricité etc.)
                                </li>
                              )}
                            </ul>
                          </div>
                          {/* {offre?.title !== "KC PREMIUM" && (
                            <div className="abonnement-check-list-item">
                              <div className="content-text-check-list ps-4">
                                <p className="text-check-list-last mb-0">
                                  +70% Appareils (dentition, vue, motricité
                                  etc.)
                                </p>
                              </div>
                            </div>
                          )} */}
                          <div className="abonnement-check-list-item-btn-container mt-3">
                            {offre?.title !== "KC PREMIUM" ? (
                              <button
                                className={`btn abonnement-check-list-item-pre-commande item-pre-btn-commande${
                                  index + 1
                                }`}
                                onClick={() => {
                                  openModalInfosUser();
                                  setOffre(offre);
                                  setIndex(index + 1);
                                }}
                                disabled={!offre?.pre_commande}
                              >
                                Pré-commande
                              </button>
                            ) : (
                              <button
                                className={`btn abonnement-check-list-item-pre-commande btn-kivu-custom-size item-pre-btn-commande${
                                  index + 1
                                }`}
                                disabled
                              >
                                Prochainement disponible
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <AlertInfo message="Aucune offres pour le moment" />
                )}
              </div>
            </Slide>
            <InfosUserForOrderModal
              modalInfosUserIsOpen={modalInfosUserIsOpen}
              setInfosUserIsOpen={setInfosUserIsOpen}
              offre={offre}
              Index={Index}
            />
          </div>
        </div>
      </section>
      <section className="section-about-crypta section-faqs mb-md-5">
        <div className="landingpage-crypta-container">
          <div className="abonnement-section pb-0">
            <div className="content-title-crypta-landingpage">
              <Bounce top>
                <h2 className="title-crypta-landingpage">
                  Mieux nous connaitre
                </h2>
              </Bounce>
            </div>
            <div className="row row-faq-landingpage mt-4 d-flex align-items-center">
              <Slide left>
                <div className="col-lg-6 col-md-8 col-left-faq-landingpage">
                  <div className="content-col-left-faq-landingpage">
                    <div className="container-coolapse-questions mt-5">
                      {faqs?.results?.map((item) => (
                        <details
                          className="card card-collapse-questioncard mb-3"
                          key={item?.slug}
                        >
                          <summary className="card__header">
                            <p className="title-collapse-question mb-0">
                              {item?.titre}
                            </p>
                            <span className="card__indicator"></span>
                          </summary>
                          <div
                            className="card__body"
                            dangerouslySetInnerHTML={createMarkup(
                              item?.description
                            )}
                          />
                        </details>
                      ))}
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide right>
                <div className="col-lg-6 col-md-4 col-right-faq-landingpage">
                  <div className="content-col-right-faq-landingpage">
                    <div className="content-img-tel flex-r">
                      <img
                        src={Tel}
                        alt="Téléphone"
                        className="img-tel-faq-landingpage"
                      />
                    </div>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </section>

      <DownloadAppSection />
      <ButtonScrollTop />
      <FooterLandingpage />
    </div>
  );
};

export default LandingPage;
