import React from "react";
import ListeOffresTable from "./ListeOffresTable";
import { AiOutlinePlusSquare } from "react-icons/ai";
import Switch from "react-switch";
import {
  useGetOptionQuery,
  useUpdateOptionMutation,
} from "../../../../utils/api/option/option.api";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";

const SwitchOption = () => {
  const { data, isLoading } = useGetOptionQuery();
  const [sendData] = useUpdateOptionMutation();
  const handleSwitchBtn = (val: boolean) => {
    Swal.fire({
      title: `Êtes-vous sure de vouloir  ${
        val === true ? "activer" : "désactiver"
      } le boutton d'abonnement de l'application mobile ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.blue,
      confirmButtonColor: Color.blue,
      preConfirm: () => {
        return sendData({ slug: data?.slug, subscription_button: val });
      },
      allowOutsideClick: () => !Swal.isLoading(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value?.data && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le bouton a été ${
              val ? "activé" : "désactivé"
            } avec succèss!`,
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return (
    <label className="d-flex align-items-center gap-4">
      <span>
        Active/Désactiver le bouton d'abonnement sur l'application mobile
      </span>
      {!isLoading && data?.id && (
        <Switch
          onChange={(val) => handleSwitchBtn(val)}
          checked={data?.subscription_button}
          uncheckedIcon={
            <div className="toggle-style-off toggle-style">Non</div>
          }
          checkedIcon={<div className="toggle-style-on toggle-style">Oui</div>}
          handleDiameter={25}
          height={25}
          width={50}
          offHandleColor="#C4C4C4"
          onHandleColor="#C4C4C4"
          borderRadius={2}
          className="react-switch"
          id="small-radius-switch"
        />
      )}
    </label>
  );
};

const ListeOffres = () => {
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page bg-white p-3">
        <div className="admin-page-title-header-container flex-sb align-items-center">
          <h4 className="crypta-dash-header-titre">Offres</h4>
          <div className="content-btn-add-theme">
            <button
              className="btn btn-add-theme"
              data-bs-toggle="modal"
              data-bs-target="#AddOffreModal"
            >
              <AiOutlinePlusSquare
                style={{
                  color: "#08BB90",
                  fontSize: 20,
                }}
              />
              <span className="ps-2">Ajouter une offre</span>
            </button>
          </div>
        </div>
        <div className="mb-3">
          <SwitchOption />
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <ListeOffresTable />
        </div>
      </div>
    </div>
  );
};

export default ListeOffres;
